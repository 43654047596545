import React, { useContext } from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import { LangContext } from "../context/langContext"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const NotFoundPage = ({ location }) => {
  const { lang } = useContext(LangContext)

  return (
    <Layout location={location}>
      <SEO title="404: Not found" />
      <Helmet>
        <meta name='robots' content='noindex, nofollow' />
      </Helmet>
      <div className="not-found py-main d-flex flex-column align-items-center">
        <h1 className="mb-4 title">404</h1>
        <p className="mb-1 subtitle">
          {lang === "EN"
            ? "Looks like you've got lost..."
            : "Sepertinya anda tersesat..."}
        </p>
        <p className="text-muted">
          {lang === "EN"
            ? "The page you're looking for doesn't exists or has been moved."
            : "Halaman yang anda cari tidak dapat ditemukan atau sudah dipindahkan."}
        </p>
        <Link
          to="/"
          className="btn btn-link font-size-lg fx-underline btn-font"
        >
          {lang === "EN" ? "Back to home" : "Kembali Ke Beranda"}
        </Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage
